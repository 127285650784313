import React from 'react'

const Footer = () => {
    return (
        <>
            <footer class="site-footer">
                {/* <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <h6>About</h6>
                            <p class="text-justify">Homeopathic medicines guarantee results in the most natural way for all kind of illnesses. Based on our scientific research, we, Lifespring Homeo Center created a series of well-tolerated drugs. Characterised by the broad therapeutic spectrum, we are highly acclaimed by experts from all over the word. Been in the market since a while now, located at Malad West, Mumbai, Maharashtra we have successfully delivered medicines that have proved to be an assured cure for all diseases. With continued efforts put in the right direction, our medicines have proved to be the best and most reliable ones. Contact us today!</p>
                        </div>

                       

                       
                    </div>
                    <hr />
                </div> */}
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <p class="copyright-text">Copyright &copy; 2022 All Rights Reserved by
                                <a href="#"> CEH Medical Institute</a>.
                            </p>

                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <p class="copyright-text">Developed and Maintained by
                                <a href="https://flyingdevs.com/"> FlyingDevs Technologies</a>.
                            </p>

                        </div>

                        {/* <div class="col-md-7 col-sm-6 col-xs-12" style={{marginTop: "25px"}}>
                            <ul class="social-icons">
                                <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
                                <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
                                <li><a class="dribbble" href="#"><i class="fa fa-dribbble"></i></a></li>
                                <li><a class="linkedin" href="#"><i class="fa fa-linkedin"></i></a></li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer