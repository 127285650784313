import React from 'react'

const GallerySection = () => {
    return (
        <>
            <section section class="features "> <p class="about-text">Gallery</p>
            </section>
            <section class="features">

                <div class="feature-container">
                    <img src="./assets/img/gallery1.png" style={{borderRadius:"10px"}} alt="Flexbox Feature" />
                </div>
                <div class="feature-container">
                    <img src="./assets/img/gallery2.png" style={{borderRadius:"10px"}} alt="Flexbox Feature" />
                </div>
                <div class="feature-container">
                    <img src="./assets/img/gallery3.png" style={{borderRadius:"10px"}} alt="Flexbox Feature" />
                </div>
            </section>
        </>
    )
}

export default GallerySection