import React from 'react'

const AboutUsSection = () => {
    return (
        <> <div class="row">
            <div class="column about-text-background">
                <h2 class="about-text">About Electrohomeopathy</h2>
                <p class="para-text">Electrohomeopathy, also known as electrohomoeopathy, or Mattei cancer cure, is a derivative of homeopathy, invented in the 19th century by Count Cesare Mattei. The name is derived from a combination of electro (referring to an electric bio-energy content supposedly extracted from plants and of therapeutic value, rather than electricity in its conventional sense) and homoeopathy (referring to an alternative medicinal philosophy developed by Samuel Hahnemann in the 18th century). Electrohomeopathy has been defined as the combination of electrical devices and homeopathy.</p>
            </div>
            <div class="column ">
                <img src="./assets/img/about.png" alt="aboutus" class="about-img" />
            </div>
        </div></>
    )
}

export default AboutUsSection