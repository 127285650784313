import React from 'react'

import ReactPlayer from 'react-player'

// Render a YouTube video player


const TestimonialVideo = () => {
    return (
        <><section section class="features-gallery "> <p class="about-text">Patient Reviews</p>
        </section>
            <div class="row">
                <div class="col-xs-6 col-md-4">
                    {/* <iframe width="100%" height="345" src="https://youtu.be/embed/xbMBPJkBOXc">
                    </iframe> */}
                    <ReactPlayer width="100%" height="345px" url='https://www.youtube.com/watch?v=xbMBPJkBOXc' />
                </div>
                <div class="col-xs-6 col-md-4">
                    {/* <iframe width="100%" height="345" src="https://youtu.be/embed/xbMBPJkBOXc">https://youtu.be/VN0dbuMuOHw
                    </iframe> */}
                     <ReactPlayer width="100%" height="345px" url='https://www.youtube.com/watch?v=VN0dbuMuOHw' />
                </div>
                <div class="col-xs-6 col-md-4 ">
                    {/* <iframe width="100%" height="345" src="https://youtu.be/embed/xbMBPJkBOXc">
                    </iframe> */}
                     <ReactPlayer width="100%" height="345px" url='https://www.youtube.com/watch?v=eFKwm6d_lZ8' />
                </div>
            </div></>
    )
}

export default TestimonialVideo