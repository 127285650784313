import React from 'react'

const TreatmentsSection = () => {
    return (
        <>


            <section section class="features-our-treatment"> <p class="about-text-heading" >Our Treatments</p>
            </section>
            <section class="features">

                {/* <div class="container">
  <img src="img_avatar.png" alt="Avatar" class="image" style="width:100%"/>
  
</div> */}

                <div class="feature-container container-card" >
                    <img className="image-card" src="./assets/img/woman-holds-jar-with-cosmetic-cream-her-hands.jpg" alt="Flexbox Feature" />
                    <div class="middle-card">
                        <div class="text-card">Skin Disease</div>
                    </div>
                </div>
                <div class="feature-container container-card" >
                    <img className="image-card" src="./assets/img/white-awareness-ribbon-two-hands-with-stethoscope-pink-background.jpg" alt="Flexbox Feature" />
                    <div class="middle-card">
                        <div class="text-card">Cancer</div>
                    </div>
                </div>
                <div class="feature-container container-card" >
                    <img  className="image-card" src="./assets/img/kidneyCare.jpg" alt="Flexbox Feature" />
                    <div class="middle-card">
                        <div class="text-card">Kidney Disease</div>
                    </div>
                </div>

            </section>
            <section class="features">
                <div class="feature-container container-card" >
                    <img  className="image-card" src="./assets/img/diabtes.jpg" alt="Flexbox Feature" />
                    <div class="middle-card">
                        <div class="text-card">Diabtes</div>
                    </div>
                </div>
                <div class="feature-container container-card" >
                    <img  className="image-card" src="./assets/img/para.jpg" alt="Flexbox Feature" />
                    <div class="middle-card">
                        <div class="text-card">Paralysis</div>
                    </div>
                </div>
                <div class="feature-container container-card" >
                    <img  className="image-card" src="./assets/img/lungs.jpg" alt="Flexbox Feature" />
                    <div class="middle-card">
                        <div class="text-card">Lungs Disease</div>
                    </div>
                </div>


            </section></>
    )
}

export default TreatmentsSection