import React from 'react'

const Banner = () => {
    return (
        <>
            <div class="header">
                <div class="sides">
                    <a href="#" class="logo"> <img  src="./assets/img/logo.svg"
                        alt="Company Logo" /></a>
                </div>
                <div class="sides"></div>
                <div class="info">
                    <h6 className='complexa' style={{color:'#000'}}>Complexa – Complexis – Curenter</h6>
                    <h1 style={{color:'#b84519'}}>CEH Medical Institute, Chas</h1>
                    <div class="meta">
                       
                        <h6 style={{color:'#000'}}>Rase-rakte cha shuddhe prani deerghaayurapnoti</h6>
                    </div>
                </div>
            </div>
            {/* <section class="content">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nisl turpis, porttitor et finibus id, viverra a metus. Praesent non ante sed orci posuere varius quis sit amet dui. Cras molestie magna orci, id gravida dolor molestie in. Duis sollicitudin turpis quis tortor egestas, ut ultrices nisl elementum. Vestibulum sed ipsum eget nulla laoreet cursus in ac sem. Integer a suscipit justo, quis aliquam sapien. Maecenas et tellus nibh. Vivamus tincidunt eros id commodo pellentesque.</p>
                <p align="center"><a href="https://twitter.com/nodws" class="btn twtr" target="_b">Follow me on Twitter</a>
                </p>
            </section> */}
        </>
    )
}

export default Banner