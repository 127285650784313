import React from 'react'

const ContactUs = () => {
    return (
        <>
            <h2 class="about-text">Contact Us</h2>
            <div class="row">
                <div class="column">
                {/* <iframe src="https://whatsform.com/c6l_mV" width="100%" height="600" frameBorder="0"></iframe> */}
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14554259.179133086!2d-105.54385245388013!3d37.49334218664659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sbd!4v1630777307491!5m2!1sen!2sbd" width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}
                </div>
                <div class="column ">
                    <form id="contact-form" class="bg-light p-4 p-md-5 c-form">


                        <input type="text" class="form-control form-style" name="user_name" placeholder='Name' />



                        <input type="email" class="form-control form-style" name="user_email" placeholder='Email' />

                        <textarea name="message" class="form-control " cols="30" rows="7" placeholder='Message'></textarea>
                        <input type="submit" class="btn btn-primary py-3 px-5" style={{marginTop: "15px"}} value="Send" />

                    </form>
                </div>
            </div></>
    )
}

export default ContactUs