import IndexHeader from 'components/Headers/IndexHeader'
import IndexNavbar from 'components/Navbars/IndexNavbar'
import React from 'react'
import AboutUsSection from '../components/AboutUsSection'
import Banner from '../components/Banner'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import GallerySection from '../components/GallerySection'
import Header from '../components/Header'
import ExamplesNavbar from '../components/Navbars/ExamplesNavbar'
import TestimonialVideo from '../components/TestimonialVideo'
import TopCarouselBanner from '../components/TopCarouselBanner'
import TreatmentsSection from '../components/TreatmentsSection'

const Home = () => {
    return (
        <>
            <div class="page-wrapper">
                <Header />
                {/* <TopHea */}
                {/* <IndexHeader/> */}
                <Banner/>
                {/* <TopCarouselBanner /> */}
                <TreatmentsSection />
                <AboutUsSection/>
                <GallerySection />
                <TestimonialVideo />
                <ContactUs />
                {/* <section class="headline">
                    <h1>Responsive Navigation</h1>
                    <p>Using CSS grid and flexbox to easily build navbars!</p>
                </section> */}
                <Footer />

            </div>
            {/* <a href="tel:7488828903" class="float">
                <i class="fa fa-phone my-float" style={{marginTop: "20px"}}></i>
            </a> */}
        </>
    )
}

export default Home