import React from 'react'

const Header = () => {
    return (
        <>
            <div class="nav-wrapper">
                <nav class="navbar">
                <div class="navbar-header">
                        <a class="navbar-brand" href="#"></a>
                    </div>

                    {/* <div class="menu-toggle" id="mobile-menu">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div> */}
                    <ul class="nav navbar-nav  navbar-right">
                        <li class="nav-item"><a href="#">Home</a></li>
                        <li class="nav-item"><a href="#">About</a></li>
                        {/* <li class="nav-item"><a href="#">Work</a></li>
                        <li class="nav-item"><a href="#">Careers</a></li> */}
                        <li class="nav-item"><a href="#">Contact Us</a></li>
                        {/* <li class="nav-item">
                            {/* <a href="tel:7488828903" class="float"> */}
                        {/* <button type="button" class="btn btn-outline-warning">Call Now</button> */}
                        {/* </a> </li> */}
                        <li class="callNow"> <a href="tel:7488828903" ><button type="button" class="btn btn-outline-warning">Call Now</button></a></li>


                    </ul>
                    {/* <ul class="nav navbar-nav navbar-right">
                        <li> <button type="button" class="btn btn-outline-warning">Call Now</button></li>

                    </ul> */}
                </nav>
            </div>
            {/* <nav class="navbar navbar-inverse">
                <div class="container-fluid">
                    <div class="navbar-header">
                        <a class="navbar-brand" href="#">WebSiteName</a>
                    </div>
                    <ul class="nav navbar-nav">
                        <li class="active"><a href="#">Home</a></li>
                        <li><a href="#">Page 1</a></li>
                        <li><a href="#">Page 2</a></li>
                    </ul>
                    <ul class="nav navbar-nav navbar-right">
                        <li><a href="#"><span class="glyphicon glyphicon-user"></span> Sign Up</a></li>
                        <li><a href="#"><span class="glyphicon glyphicon-log-in"></span> Login</a></li>
                    </ul>
                </div>
            </nav> */}
        </>
    )
}

export default Header